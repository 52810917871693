@font-size-base: 12px;
body {
  font-size: @font-size-base;
}

/* NOSONAR */
ant-steps-dot .ant-steps-item-title,
.ant-steps-dot .ant-steps-item-title {
  font-size: 14px;
}

.ant-steps-label-vertical .ant-steps-item {
  cursor: pointer;
}

.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  margin-top: 3px;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.full-width {
  width: 100%;
}

.ant-switch {
  margin: 2px 0 4px;
  height: 28px;
  min-width: 72px;
  border-radius: 30px;
  line-height: 25px;

  &::after {
    width: 24px;
    height: 24px;
    border-radius: 24px;
  }
}
