.highlight-error {
  border: 1px solid red !important;
  border-radius: 4px !important;
}

.full-width {
  width: 100%;
}

.red-asterisk {
  color: red;
  position: absolute;
  top: 50%;
  right: 8px;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
}

.equipment-error-message {
  position: absolute;
  left: 0;
  margin-top: -10px;
  color: red;
}

.equipment-field-type {
  position: relative;
}

.expiration-date-expired {
  color: red;
}

.expiration-date-not-expired {
  color: inherit;
}

.expiration-icon {
  margin-left: 5px;
}
