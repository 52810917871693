@charset 'UTF-8';

@font-face {
  font-family: 'benchmark-icons';
  src: url('../../fonts/bdm-icons.eot');
  src: url('../../fonts/bdm-icons.eot?#iefix') format('embedded-opentype'),
    url('../../fonts/bdm-icons.woff') format('woff'),
    url('../../fonts/bdm-icons.ttf') format('truetype'),
    url('../../fonts/bdm-icons.svg#benchmark-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[data-icon]:before {
  font-family: 'benchmark-icons', sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='bdm-icon-']:before,
[class*=' bdm-icon-']:before {
  font-family: 'benchmark-icons', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bdm-icon-daily-observation-report:before {
  content: '\61';
}
.bdm-icon-assign:before {
  content: '\62';
}
.bdm-icon-flag:before {
  content: '\63';
}
.bdm-icon-ia:before {
  content: '\64';
}
.bdm-icon-report:before {
  content: '\65';
}
.bdm-icon-star:before {
  content: '\66';
}
.bdm-icon-community:before {
  content: '\67';
}
.bdm-icon-evaluation:before {
  content: '\68';
}
.bdm-icon-note:before {
  content: '\69';
}
.bdm-icon-process:before {
  content: '\6a';
}
.bdm-icon-training:before {
  content: '\6b';
}
.bdm-icon-arrows:before {
  content: '\6c';
}
.bdm-icon-handcuffs:before {
  content: '\6d';
}
.bdm-icon-ic-dor-1:before {
  content: '\6e';
}
.bdm-icon-injury:before {
  content: '\6f';
}
.bdm-icon-siren:before {
  content: '\70';
}
.bdm-icon-uof:before {
  content: '\71';
}
.bdm-icon-delete:before {
  content: '\72';
}
.bdm-icon-filter:before {
  content: '\73';
}
.bdm-icon-history:before {
  content: '\74';
}
.bdm-icon-print:before {
  content: '\75';
}
.bdm-icon-save:before {
  content: '\76';
}
.bdm-icon-search:before {
  content: '\77';
}
.bdm-icon-analytics:before {
  content: '\78';
}
.bdm-icon-care:before {
  content: '\79';
}
.bdm-icon-collapse:before {
  content: '\7a';
}
.bdm-icon-expand:before {
  content: '\41';
}
.bdm-icon-first-sign:before {
  content: '\42';
}
.bdm-icon-home:before {
  content: '\43';
}
.bdm-icon-internal-affairs:before {
  content: '\44';
}
.bdm-icon-internal-affairs-1:before {
  content: '\45';
}
.bdm-icon-log-out:before {
  content: '\46';
}
.bdm-icon-new-report:before {
  content: '\47';
}
.bdm-icon-report-1:before {
  content: '\48';
}
.bdm-icon-new-case-file:before {
  content: '\47';
}
.bdm-icon-case-file:before {
  content: '\69';
}
.bdm-icon-settings:before {
  content: '\49';
}
.bdm-icon-user-profile:before {
  content: '\4a';
}
.bdm-icon-activity:before {
  content: '\4b';
}
.bdm-icon-community-engagement:before {
  content: '\4c';
}
.bdm-icon-internal-affairs-2:before {
  content: '\4d';
}
.bdm-icon-officer-profile:before {
  content: '\4e';
}
.bdm-icon-performance-evaluation:before {
  content: '\4f';
}
.bdm-icon-training-1:before {
  content: '\50';
}
.bdm-icon-use-of-force:before {
  content: '\51';
}
.bdm-icon-vehicle-pursuit:before {
  content: '\70';
}
.bdm-icon {
  font-size: 18px;
  margin-left: 8px;
  margin-right: 8px;
  vertical-align: middle;
  line-height: 1;
}
