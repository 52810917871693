@dark-gray: #001529;

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNextLTPro-Regular.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNextLTPro-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNextLTPro-Demi.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNextLTPro-Bold.otf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'AvenirNext';
  src: url('../../fonts/AvenirNextLTPro-It.otf');
  font-weight: normal;
  font-style: italic;
}

html,
html body {
  font-family: AvenirNext, sans-serif;
  background-color: #e9edf1;
  color: @dark-gray;
}

a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

h1 {
  font-size: 32px;
  font-weight: 400;
  color: @dark-gray;
  line-height: 40px;
}

h2 {
  font-size: 20px;
  font-weight: 500;
  color: @dark-gray;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  font-weight: 500;
  color: @dark-gray;
  line-height: 25px;
}

.page-content {
  padding: 20px 20px 60px 20px;
}

.ant-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border: solid 1px #0e4c87;
  background-color: transparent;
  color: #0e4c87;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  border-radius: 24px;
  box-shadow: none;
  height: auto;

  & > span {
    padding-top: 3px;
  }

  &:hover {
    border: 1px solid #226fb7;
    background-color: transparent;
    color: #226fb7;
  }

  &:disabled {
    border: 1px solid rgba(78, 78, 78, 0.4) !important;
    color: rgba(78, 78, 78, 0.85) !important;
  }

  &:disabled:hover {
    color: rgba(78, 78, 78, 0.85) !important;
  }

  & .anticon {
    font-size: inherit;
    height: 19px;
  }

  &.ant-btn-danger {
    color: #fff;
    background-color: #ff4d4f;
    border-color: #ff4d4f;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
  }
}

.ant-btn-circle {
  padding: 5px !important;
  width: 42px !important;
  height: 42px !important;
}

a.ant-btn {
  padding-top: 8px;
  line-height: normal;
}

.ant-btn-primary {
  border: solid 1px #0040dd;
  background-color: #0040dd;
  color: #fff;

  &:hover {
    border: solid 1px #41669f;
    background-color: #41669f;
    color: #fff !important;
  }

  &:disabled {
    border: solid 1px #ededed;
    background-color: #ededed;
    color: #4e4e4e;
  }
}

.ant-btn-background-ghost {
  border: 1px solid #0e4c87 !important;
  color: #0e4c87 !important;
  background-color: transparent !important;

  &:hover {
    border: 1px solid #226fb7 !important;
    background-color: transparent !important;
    color: #226fb7 !important;
  }

  &:disabled {
    border: 1px solid #4e4e4e !important;
    color: #4e4e4e !important;
  }

  // DO NOT USE !important IS A BAD PRACTICE
  // I have to because someone already use it
  // and I could breake the design if I remove it
  // this is a fix for ghost button style
  &.ant-btn-danger {
    color: #ff4d4f !important;
    background-color: transparent !important;
    border-color: #ff4d4f !important;

    &:hover {
      color: #ff7875 !important;
      background-color: transparent !important;
      border-color: #ff7875 !important;
    }
  }
}

.ant-input,
.ant-input-number,
.ant-select,
.ant-calendar-picker,
.ant-select-auto-complete,
.ant-calendar-picker-input {
  border: solid 1px rgba(78, 78, 78, 0.5);
  color: #4e4e4e;
  font-size: 14px;
  border-radius: 0;

  &:hover,
  &:focus {
    border-color: #2664eb;
    border-right-width: 1 px;
  }

  &::placeholder,
  & .ant-select-selection__placeholder {
    font-weight: 400;
    color: rgba(78, 78, 78, 0.8) !important;
    line-height: 24px;
  }

  & .ant-select-selection__rendered {
    line-height: 32px;
  }
}

.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled),
.ant-select-dropdown-menu-item-selected {
  background-color: rgba(112, 179, 242, 0.25);
}

textarea::placeholder {
  font-weight: 400;
  color: rgba(78, 78, 78, 0.8) !important;
  line-height: 24px;
}

.ant-calendar-range-picker-input {
  &::placeholder {
    font-weight: 400;
    color: rgba(78, 78, 78, 0.8) !important;
    line-height: 24px;
  }
}

.ant-input {
  height: 34px;
}

.ant-select .ant-select-selection {
  border: none !important;
}

.ant-select-search {
  border: none !important;
}

.ant-select-auto-complete .ant-input {
  border: none;
}

.ant-select:focus .ant-select-search__field,
.ant-select-search:focus .ant-select-search__field,
.ant-select-auto-complete.ant-select .ant-input:hover,
.ant-select-auto-complete.ant-select .ant-input:focus {
  border: none !important;
  border-right-width: 0;
  box-shadow: none;
}

.ant-calendar-picker .ant-calendar-picker-input {
  border: none !important;
  border-right-width: 0;
  box-shadow: none;
}

.ant-calendar-picker:focus .ant-calendar-picker-input {
  border: none !important;
  box-shadow: none;
}

.ant-switch {
  line-height: 20px;
  font-size: 12px;
  font-weight: 500;
}

.ant-switch-checked {
  background-color: #0e4c87;
  color: #fff;
}

.ant-select .ant-select-selection {
  border-radius: 0;

  &:hover {
    border: solid 1px #4e4e4e;
  }
}

.ant-form label,
label.checkbox span,
label.ant-checkbox-wrapper,
label.ant-checkbox-wrapper span {
  font-size: 12px;
  color: @dark-gray;
}

.ant-form-item-label {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  white-space: normal;
}

.ant-form-item-label > label {
  color: @dark-gray;
}

.ant-select {
  border-radius: none;
}

.ant-btn:before {
  opacity: 0;
}

.activities-panel-title h2 {
  font-weight: 600;
  font-size: 24px;
  color: @dark-gray;
  line-height: 40px;
}

.activities-panel-title h4 {
  font-size: 14px;
  color: @dark-gray;
}

.header-title {
  align-items: center;
}

.header-title h1,
.header-title__text {
  font-size: 32px !important;
  font-weight: 600 !important;
  color: @dark-gray;

  small,
  span.linking-form-description {
    display: block;
    font-size: 16px;
    line-height: 1.5rem;
    font-weight: 500;
    color: rgba(0, 21, 41, 0.75);

    & > span > span {
      margin-right: 5px;
    }
  }
}

.header-title .back-button {
  margin-right: 10px;
  font-size: 24px;
  color: #0040dd;
}

.header-info {
  width: 100%;
}

.header-info .steps-navigation {
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
}

.header-actions {
  font-size: 14px;
  font-weight: 500;
  color: #4e4e4e;

  & p {
    color: #4e4e4e;
  }

  & p span {
    font-weight: 600;
  }
}

button#advanced-filter {
  background-color: rgba(255, 255, 255, 0.5);
  color: #4e4e4e;
  font-size: 15px;
  font-weight: 500;
  border: solid 1px #69696f;
  border-radius: 0;
}

h2.custom-section-page-title {
  font-size: 20px;
  font-weight: 600;
  color: @dark-gray;
}

.ant-collapse-header {
  align-items: center;
}

.ant-collapse-header .ant-collapse-arrow {
  color: #0040dd !important;
  font-size: 24px !important;
}

.ant-collapse-header .ant-collapse-extra {
  margin-right: 10px;
}

.ant-tag {
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  color: @dark-gray !important;
  line-height: normal;
  margin-left: 5px;
  cursor: inherit;

  &:hover {
    opacity: 1;
  }
}

.ant-tag.status-indicator {
  width: 130px;
}

.ant-avatar.status-indicator {
  padding: 8px 16px;
  margin-left: 5px;
  border-radius: 24px;
  width: 60px;
  height: 32px;
  line-height: normal;
  font-size: 16px;
  font-weight: 600;
  color: @dark-gray;
  background-color: #d4dce2;
}

.ant-tag.status-take-action {
  background-color: #fad74a !important;
}

.ant-tag.status-closed {
  background-color: #93dba9 !important;
}

.ant-tag.status-in-review {
  background-color: #fb8d63 !important;
}

.ant-tag.status-draft {
  background-color: #e1c8f0 !important;
}

.bdm-panel-activity-list .bdm-panel-row {
  font-size: 15px;
  font-weight: 500;

  & .meta-content .meta-updated {
    font-size: 14px;
    font-weight: 500;
  }

  & .meta-content .ant-btn {
    color: #0040dd;
  }
}

.ant-menu-item-selected {
  padding-left: 18px !important;
}

.ant-menu-submenu-popup,
.ant-menu-submenu > .ant-menu {
  margin-top: -10px;
}

.ant-menu-inline .ant-menu-item {
  text-overflow: clip;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.ant-table {
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  color: @dark-gray;
}

.ant-table .ant-table-thead {
  background-color: #e4e0df;
}

.ant-table .ant-table-thead > tr > th,
.ant-table-fixed-header .ant-table-scroll .ant-table-header tr th {
  padding: 16px !important;
  font-size: 16px !important;
  font-weight: 600;
  color: @dark-gray !important;
}

.ant-table-thead > tr > th {
  background: inherit;
  background-color: inherit;
}

.ant-table-fixed-header .ant-table-scroll .ant-table-header {
  overflow: hidden;
}

.administrator-wrapper .admin-container a {
  &:hover {
    text-decoration: none;
  }
}

.administrator-wrapper .admin-container .ant-btn {
  width: 100%;
  height: 150px;
  flex-direction: column;
  flex-wrap: wrap;
  border-radius: 5px;
  background: #fff;
  font-weight: 500;
  border: solid 1px #ccc;
  color: @dark-gray;

  &:hover {
    color: #41669f;
  }

  & .anticon {
    margin-bottom: 10px;
    display: block;
    font-size: 42px;
    width: 42px;
    height: 42px;
    color: #0040dd;
  }

  & span {
    display: block;
  }
}

.ant-empty {
  color: #737373;
}

.review-list {
  margin: 0 20px;

  @media print {
    margin: 0;
  }
}

.dashboard-container {
  margin: 20px 4px 80px 4px;
}

.ant-modal-header {
  padding-right: 50px;
}

.ant-modal-header .ant-modal-title {
  font-size: 18px;
  font-weight: 500;
  color: @dark-gray;
}

.form-actions {
  align-items: flex-start;
}

.form-actions .form-actions__buttons button img {
  width: 24px;
  height: 24px;
}

.form-actions .form-actions__buttons .ant-btn .anticon {
  font-size: 20px;
}

.steps-actions {
  padding: 20px;
}

.ant-layout-sider-collapsed ul li {
  text-align: center;
}

.ant-layout-sider-collapsed ul li .anticon {
  margin: 0;
}
