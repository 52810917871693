.footer {
  background-color: #fdfdfd;
  border-top: 1px;
  border-color: #fdfdfd;
  border-top-color: #e9e9e9;
  border-style: solid;
  padding: 10px 16px;
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: left;
  width: 100%;
}

.ml-1 {
  margin-left: 8px;
}

.mt-2 {
  margin-top: 16px;
}
