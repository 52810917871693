.info-tooltip-wrapper {
  display: inline-block;

  .anticon {
    font-size: 0.8em;
    color: #3772ff;
    cursor: help;
  }

  &.info-tooltip-wrapper-formfield {
    .anticon {
      font-size: 1.4em;
    }
  }
}

.info-tooltip {
  font-size: 1.1em;

  .info-tooltip-line {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 10px;
  }

  &.info-tooltip-large {
    .ant-tooltip-inner {
      max-width: 300px;
    }
  }
}
