.ant-modal .ant-form-item {
  margin-bottom: 12px;
}

.ant-popover-title {
  background-color: #6196ff;
  color: white;
  font-weight: bold;
}

.ant-popover-inner-content {
  text-align: justify;
  background-color: white;
}
