@import '~antd/dist/antd.less';
@import '~antd/lib/style/v2-compatible-reset.less';

@import './antd-v2-reset.less';
@import './common/icons.less';
@import './common/design.less';
@import './common/main.less';
@import './common/print.less';
@import './components/tooltips.less';

@media screen and (max-width: 480px), (max-width: 992px) {
  .menu-fab {
    display: flex;
    position: fixed;
    left: 12px;
    bottom: 12px;
    width: 48px;
    height: 48px;
    background-color: #3772ff;
    justify-content: center;
    align-items: center;
    color: #333;
    z-index: 991;
    outline: none;
    border: none;
    border-radius: 50%;
    box-shadow: 0px 0.9px 9px rgba(0, 0, 0, 0.1),
      0px 3.1px 3.5px -0.7px rgba(0, 0, 0, 0.1),
      0px 7.5px 9px -1.3px rgba(0, 0, 0, 0.1),
      0.1px 15px 20px -2px rgba(0, 0, 0, 0.1);
  }

  .menu-fab:hover {
    background-color: #3772ff;
    color: #fff;
    border: none;
  }

  .menu-fab img {
    height: 24px;
    width: 24px;
  }

  .new-sidebar-footer {
    @media screen and (max-width: 480px) {
      display: flex;
      flex-direction: column-reverse;

      i {
        display: none;
      }

      button.daynfe.ant-btn {
        height: 64px;
      }

      .daynfe.ant-btn img.footer-logo {
        float: right;
      }
    }
  }

  p.status-badge,
  .card-line,
  .linked-form-details {
    padding: 5px;
    font-size: 15px;
    font-weight: 500;
    color: #4e4e4e;
  }

  .linked-form-details {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .help-icon-mobile {
    display: none;
  }

  .bdm-form-review-panel {
    .ant-row.ant-form-item.bdm-form-field {
      display: flex;

      align-items: start;

      .ant-col.ant-form-item-label {
        flex: unset;
        width: unset;
      }
    }

    .ant-col.ant-form-item-label {
      flex: unset;
      align-self: flex-start;
    }
  }
}

.bdm-form-wrapper {
  .ant-form-item.bdm-form-item {
    .has-error {
      .ant-select {
        border: 1px solid red;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .page-header-content {
    width: 100% !important;
  }

  p.status-badge {
    white-space: wrap;
    flex-shrink: unset;
    height: 50px;
    font-size: 11px;
  }

  .bdm-form-wrapper {
    .ant-form-item.bdm-form-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .ant-form-item-control-wrapper {
      width: unset;
    }

    .ant-form-item-label,
    .ant-form-item-control-wrapper {
      flex: 1;
    }

    .ant-form-item-control-wrapper {
      flex-shrink: unset;
    }
  }

  .help-button {
    display: none;
  }

  .header-info {
    padding-left: 12px;
  }

  .loading-placeholder {
    .form-info__back {
      display: none;
    }

    padding-left: 6px !important;
    padding-right: 12px !important;
  }

  #form-header {
    margin-bottom: 100%;
    padding-left: 6px;
    padding-right: 12px;
    padding-bottom: 6px;

    .form-info__back {
      display: none;
    }

    .form-info__status {
      margin-right: 0;
    }

    .title {
      flex: 1;
    }
  }

  .help-icon-mobile {
    img {
      margin: 0 8px;
    }

    display: block;
  }

  .action-buttons-1 button:last-child {
    margin-right: 0;
  }

  .field-label-format {
    margin-top: 16px;
  }

  .bdm-form-field.ant-row::before {
    content: none;
  }
}
.bdm-form-section-first.bdm-form-section-contributor-assignment-section,
.bdm-form-section-not-first.bdm-form-section-contributor-assignment-section {
  background-color: #233751fa;
  color: #eee;
  margin-left: -16px;
  margin-top: -22px;
  margin-right: -16px;
  margin-bottom: 24px;
  padding: 0 16px;
  border-radius: 6px 6px 0 0;

  > .ant-row {
    display: grid;
    align-items: center;
    grid-template-columns: auto min-content;
    grid-template-rows: minmax(0, 1fr);
    grid-template-areas:
      'name assigned-date'
      'name completed-date';
    margin: 0;
    gap: 16px;
    padding-bottom: 16px;
    height: 120px;

    .ant-col {
      padding: 0;
      margin: 0;
    }
    .ant-form-item-label {
      width: 0;
    }

    > .assignee-select {
      grid-area: name;
      margin: 0;

      [validationstatus] {
        margin: 0;
      }
    }

    > div:nth-of-type(1) {
      grid-area: name;
      margin: 0;

      [validationstatus] {
        margin: 0;
      }
      .status {
        color: white;
      }
    }
    > div:nth-of-type(2) {
      grid-area: assigned-date;
      [validationstatus] {
        margin: 0;
      }
    }
    > div:nth-of-type(4) {
      grid-area: completed-date;
      [validationstatus] {
        margin: 0;
      }
    }

    > div:nth-of-type(3) {
      display: none;
    }
  }

  .complete-check {
    span {
      color: #eee;
    }
    .ant-checkbox {
      order: 1;
    }
    display: flex;
    align-items: center;
  }
  .reviewer-field.assigned-date {
    padding: 0;
    margin: 0;
    color: #eee;
  }
  .reviewer-field.text-field.textarea-field.contributor-assignment-select {
    color: #eee;
  }
}
.contributor-assignment-title {
  display: flex;
  padding-top: 16px;
  font-size: 18px;
  font-weight: bold;
}

@font-family: "AvenirNext", sans-serif;@primary-color: #0040DD;@btn-primary-color: #FFF;@btn-default-bg: #FFF;@btn-default-color: #0E4C87;@btn-default-border: #0E4C87;@ACCENT: #0E4C87;@BASE: #61B5EB;@DIVIDER: #e9e9e9;@FONT_FAMILY: "AvenirNext", sans-serif;@GRAY_1: #9EB3C2;@GRAY_2: #595959;@GRAY_3: #e9e9e9;@GRAY_4: #afafaf;@GRAY_5: #929292;@INACTIVE: rgba(78, 78, 78, .8);@SUCCESS: #2ECC71;@ERROR: #E74C3C;@PURPLE: #E1C8F0;@ORANGE: #FB8D63;@GREEN: #93DBA9;@YELLOW: #FAD74A;@SILVER: #D4DCE2;@ANCHORS: #60C0EC;@PINK: #F17E94;@WHEEL_BACKGROUND_COLOR: #C8D8FF;@WHEEL_ACTIVE_COLOR: #3772FF;@WHEEL_INACTIVE_COLOR: #767A84;@BLACK: #000;@BLUE: #3772FF;@DARK_GRAY: #001529;@SIDEBAR_BG: #041528;@SIDEBAR_INACTIVE: #FFFFFF;@SIDEBAR_DIVIDER: #13151D;@SIDEBAR_DIVIDER_2: #1E222E;@SIDEBAR_ICON: #6ED960;@SIDEBAR_HOVER: #FFFFFF;@SIDEBAR_HOVER_BG: #393939;@SIDEBAR_FOOTER_BG: #001529;@AVATAR_BG: #0040DD;@AVATAR_BORDER: #0040DD;@AVATAR_COLOR: #FFF;@SIDEBAR_QUICKLINK_COLOR: #9B9EA5;@SIDEBAR_DISCLAIMER_COLOR: #FFFFFF;@SIDEBAR_ACTIVE: #FFFFFF;@SIDEBAR_ACTIVE_BG: rgba(12, 71, 213, 0.25);@SIDEBAR_ACTIVE_STROKE: #E4E0DF;@BG: #E8EDF1;