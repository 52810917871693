.operator-button {
  width: 12px;
  margin: 8px;
}

button.ant-btn.operator-button.ant-btn-primary.ant-btn-circle.ant-btn-sm.ant-btn-icon-only.ant-btn-background-ghost {
  width: 16px !important;
  height: 24px !important;
}

.operator-button > i.anticon {
  height: 13px;
}
