/* Hides Select All checkbox for Ant Design Table Component */
.ant-table-thead tr .ant-table-selection-column div {
  display: none;
}

.due-date-error-color {
  color: #db0711;
}

.due-date-error-color > svg {
  color: #db0711;
}

.casefile-icon-due-date {
  font-size: 16px;
  margin-left: 8px;
}

.casefile-task-done {
  color: #b9bdbb;
}

div.overview-action-button-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: end;
      justify-content: flex-end;
  width: 100%;
}
