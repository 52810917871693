@baseColumn: 100 / 24;

.popout-window-overlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  height: 100%;
  z-index: 99999;
  overflow: auto;
  padding: 50px;

  h1 {
    border-bottom: 1px solid #ccc;
    padding-bottom: 0;
    margin-bottom: 30px;
  }

  h3 {
    margin-top: 25px;
    margin-bottom: 10px;
    font-weight: 600;
  }
}

body.no-flow {
  overflow: hidden;
}

.popin-window-overlay {
  &--backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.95;
    z-index: 99;
  }

  &--content {
    position: absolute;
    z-index: 100;

    p {
      margin-bottom: 15px;
    }
  }
}

.generate-columns(@n, @i: 1) when (@i =< @n) {
  .ant-col-@{i},
  .ant-col-sm-@{i} {
    width: (@i * 100% / @n);
  }

  .generate-columns(@n, (@i + 1));
}

.generate-columns-full(@n, @i: 1) when (@i =< @n) {
  .ant-col-@{i},
  .ant-col-sm-@{i} {
    width: 100%;
  }

  .generate-columns-full(@n, (@i + 1));
}

@media print {
  body {
    font: 10pt Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif !important;
    line-height: 1em;
  }

  #scrollable-sections {
    padding: 0;
  }

  .ant-form label {
    font-size: 10pt;
  }

  .print-frame {
    width: 100%;

    table {
      width: 100%;
      page-break-inside: auto;

      tr {
        page-break-inside: avoid;
        page-break-after: auto;
      }

      thead {
        display: table-header-group;
      }

      tfoot {
        display: table-footer-group;
      }
    }

    .page-header-space {
      height: 70px;
    }

    .page-header-content,
    .print-frame-header,
    .print-frame-footer {
      display: none;
    }
  }

  .print-frame {
    .print-frame-header {
      position: fixed;
      top: 0;
    }

    .bdm-form-viewer {
      padding-bottom: 0;
    }

    .print-frame-footer {
      position: fixed;
      bottom: 0;

      .page-footer-space {
        height: 45px;
      }
    }
  }

  body {
    font: 10pt Calibri, Candara, Segoe, 'Segoe UI', Optima, Arial, sans-serif;
    line-height: 1em;
    overflow: auto;

    .bdm-form {
      padding-top: 0 !important;
      line-height: 1em;
      font-size: 10pt;

      .bdm-panel,
      .bdm-form-wrapper {
        .header-wrapper {
          display: block;
          position: fixed;
          top: 0;
          width: 100%;
          z-index: 1000;

          .agency,
          .report {
            white-space: normal;
          }

          .agency {
            font-size: 12pt;
            line-height: 13pt;
          }

          .report {
            text-align: center;
            font-size: 14pt;
            font-weight: 700;
            line-height: 15pt;

            .name {
              margin-bottom: 5px;
            }
          }

          .submitted {
            text-align: right;

            .printed {
              font-size: 10pt;
            }
          }
        }

        .footer-wrapper {
          display: block;
          position: fixed;
          bottom: 0;
          width: 100%;
          z-index: 1000;
          text-align: center;
        }

        .bdm-form-section-title {
          text-align: left;
          font-size: 10pt;
          font-weight: 700;
          line-height: 1em;
          margin-bottom: 2em;
        }

        .ant-form-item {
          font-size: 10pt;
          line-height: 1em;
          margin: 1.5em 0 0.7em 0;

          .reviewer-field {
            &.text-field,
            &.select-field {
              line-height: 1em;
              border-bottom: none;
            }

            &.select-field,
            &.textarea-field {
              padding: 0;
              border-bottom: none;
            }
          }

          .ant-form-extra {
            padding-top: 0;
          }

          .ant-form-item-control {
            line-height: 1em;
          }

          .ant-form-item-label {
            top: 0.4em;
            text-align: left;
            line-height: 1em;

            & > label {
              font-size: 10pt;
              font-weight: 700;

              &.ant-form-item-no-colon::after {
                content: ':';
              }
            }
          }
        }

        .ql-editor {
          page-break-inside: avoid;
        }
      }
    }

    .required,
    .info-tooltip-wrapper i,
    #form-header,
    .help-button {
      display: none;
    }
  }

  @page {
    margin: 1cm 1cm 2cm;
    size: letter portrait;
  }

  .popout-window-overlay {
    display: none;
  }

  .ant-layout-sider,
  .action-buttons,
  .form-header-content .status-indicator,
  .bdm-footer,
  .bdm-form-viewer .ant-alert-info,
  .bdm-form-review-panel {
    display: none;
  }

  .content-wrapper {
    padding: 0 !important;
  }

  .ant-layout-content,
  .ant-layout-content,
  .ant-layout,
  .content-wrapper {
    margin: 0 !important;
    background-color: #fff !important;
  }

  .body-diagram {
    height: 50%;
    width: 50%;
  }

  .bdm-section-wrapper {
    margin: 10px 0 10px;
    padding: 5px 10px;
  }

  .bdm-panel,
  .steps-tab-content {
    margin-bottom: 2em;
    padding-bottom: 1em;
    padding-top: 0em;
    margin-top: 2em;
    page-break-after: auto;

    &:first-child {
      padding-top: 2em;
      margin-top: 0em;
    }

    .bdm-panel-title,
    .steps-tab-title {
      padding: 2pt 0;
      font-size: 12pt;
      text-transform: uppercase;
      text-align: center;
      background-color: lightgray;
      border: solid 0.5pt black;
    }
  }

  .ant-form-item {
    margin-bottom: 1em;
  }

  .bdm-form-section {
    border: none;
    page-break-after: auto;
    widows: 10;
  }

  .form-name {
    font-size: 18pt;
    margin-bottom: 5pt;
    padding-left: 8px;
  }

  .bdm-form-viewer {
    .reviewer-field.text-field {
      margin-left: 1em;
    }

    .reviewer-field.select-field,
    .reviewer-field.textarea-field {
      padding: 5px 10px 5px 0;
      margin-left: 1em;
    }
  }

  .reviews-list {
    &-item {
      border-radius: 0;
      margin-bottom: 3em;

      .review-item-header {
        border-top: 1px solid #e9e9e9;
        font-size: 10pt;
        padding-top: 5pt;
        padding-bottom: 5pt;
      }
    }
  }

  .bdm-form-agency-name {
    line-height: 1.5em;
    margin-top: 0.5em;
    font-size: 18px;
    font-family: 'Lato', Arial, serif;
  }

  //columns
  .ant-col {
    float: left;
  }

  .generate-columns(24);

  .form-actions {
    .generate-columns-full(24);
  }

  a[alt='download'] {
    display: none;
  }

  .hide-print {
    display: none;
  }

  .ant-layout {
    height: 100%;
    min-height: auto;
    display: block;
    flex: none;
  }

  html,
  html body {
    background: #fff;
  }
}

@media screen {
  .is-print {
    display: none;
  }
}
