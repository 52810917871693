.ant-input-number-handler-wrap {
  display: none;
}

.reminder-date-message {
  margin-right: auto;
  margin-left: calc(50% - 48px);
  height: 18px;
}

.reminder-date-message-error {
  color: #e60608;
  margin-left: auto;
}

.reminder-day-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}

.reminder-day-container > .main-field-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
      justify-content: center;
  -ms-flex-align: center;
      align-items: center;
}
