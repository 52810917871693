body * {
  min-width: 0;
}

.ant-layout-content {
  overflow: initial;
}

.ant-input {
  line-height: 1;
}

.ant-row-flex .bdm-panel.full-height {
  height: 100%;
}

.clearfix {
  display: block;
  clear: both;
}

.ant-layout {
  min-height: 100vh;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

/* Remove hover for tables */
.ant-table tr.no-hover:hover,
.ant-table tr.no-hover:hover > td {
  background: transparent !important;
}

/* Custom Popover style */
.ant-popover-inner {
  color: #000;
  background-color: #e9e9e9 !important;
  border-top-color: #e9e9e9 !important;
  text-align: center;
}

.ant-popover-arrow:after {
  border-top-color: #e9e9e9 !important;
}

.anticon.bdm-icon,
.anticon.bdm-icon::before {
  font-family: 'benchmark-icons', sans-serif !important;
}

/* Custom Tooltip */

.ant-tooltip.ant-custom-tooltip .ant-tooltip-content .ant-tooltip-inner {
  color: black;
  font-size: 12px;
  background: #e9e9e9;
  border-radius: 2px;
}

.ant-tooltip.ant-custom-tooltip .ant-tooltip-content .ant-tooltip-arrow {
  border-top-color: #e9e9e9;
  bottom: -3px;
  border-width: 10px 8px 0;
}

.select-footer {
  margin: 10px 0px;
}

.ant-modal-body {
  padding: 12px;
}

@media screen and (max-width: 991px) {
  .text-sm-left {
    text-align: left;
  }

  .text-sm-center {
    text-align: center;
  }

  .text-sm-right {
    text-align: right;
  }

  .pull-sm-none {
    float: none;
  }
}

@media screen and (max-width: 767px) {
  .text-xs-left {
    text-align: left;
  }

  .text-xs-center {
    text-align: center;
  }

  .text-xs-right {
    text-align: right;
  }

  .pull-xs-none {
    float: none;
  }
}

.bdm-input-select {
  .ant-select-selection--multiple {
    & > ul > li,
    .ant-select-selection__rendered > ul > li {
      height: auto;
      line-height: 18px;
    }
    .ant-select-selection__choice__content {
      white-space: break-spaces;
    }
  }
}

.ant-select-dropdown-menu-item {
  white-space: break-spaces;
}

.ant-select-dropdown-menu-item-group-title {
  color: #000;
  font-weight: 600;
}

.ant-form-inline .ant-form-item-select,
.ant-form-inline .ant-form-item-select .ant-select {
  min-width: 100px;

  @media screen and (max-width: 768px) {
    width: 100%;

    .ant-form-item-control-wrapper {
      width: 100%;
    }
  }
}

.ant-form-inline .ant-form-item-label {
  vertical-align: middle;
}

.ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
  padding-left: 0;
}

.ant-form-inline .ant-form-item-select > div,
.ant-form-inline .ant-form-item-select > .ant-form-item-control-wrapper {
  padding: 0;
}

.ant-tooltip .ant-badge-count {
  display: none;
}

.responsive-table {
  overflow: auto;

  tr td,
  tr th {
    word-break: inherit;
  }
}

kbd,
.kbd {
  display: inline-block;
  padding: 2px 5px;
  background: #efefef;
  border-radius: 5px;
  color: #666;
  border: 1px solid #ccc;
}

.is-relative {
  position: relative;
}

.no-flow {
  overflow: hidden;
}

.bdm-no-display-field {
  display: none;
}

.new-report-loader {
  margin-top: 15px;
}

.picker-loader {
  padding: 15px 0;
  width: 280px;
  max-width: 100%;
  margin: 0 auto;

  svg {
    width: 280px;
    max-width: 100%;
  }

  svg + svg {
    margin-top: 30px;
  }
}

.divider {
  display: block;
  margin: 24px 0;
  clear: both;
  border: 1px solid #e8e8e8;
}

// TODO: ensure this can be replaced by admin container vice versa
.administrator-content {
  margin-bottom: 50px;
}

.admin-container {
  padding: 20px;
}

.section-container {
  margin: 15px 15px;
  margin-bottom: 60px;
}

.gray-text {
  color: #bfbfbf;
}

.templates-list {
  padding-top: 30px;

  .templates-list-loader {
    max-width: 500px;
    margin: 0 auto;

    .template-loader-wrapper {
      margin-bottom: 30px;
      background-color: #fff;
      padding: 27px 10px;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #e4e1e1;

      svg {
        max-width: 280px;
      }
    }
  }

  .buttons-wrapper {
    display: flex;
    text-align: center;
    margin: 0 auto;
    height: auto;
    min-height: 70px;
    justify-content: center;
  }

  .btn-wrapper {
    margin: 0 auto;
    max-width: 500px;

    .ant-btn {
      display: block;
      width: 100%;
      margin-bottom: 30px;
      height: auto;
      min-height: 60px;
      text-align: left;
      padding: 0;

      img {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }

      .btn__text {
        text-align: center;
        align-self: stretch;
        font-size: 24px;
        vertical-align: middle;
        font-weight: 300;
        padding: 20px 10px;
        display: flex;
        align-items: center;
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        line-height: 28px;

        @media screen and (max-width: 480px) {
          max-width: 100%;
        }

        .btn__icon {
          position: relative;
          top: 1px;
          font-size: 43px;
          vertical-align: middle;
          line-height: 0;
          min-width: 35px;
          margin-right: 30px;

          @media screen and (max-width: 480px) {
            margin-right: 10px;
          }
        }

        span {
          white-space: pre-wrap;
          text-align: left;
        }
      }
    }
  }
}

.text-black {
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
}

@border-color: #e9e9e9;

.bottom-bordered {
  border-bottom: 1px solid @border-color;
}

.top-bordered {
  border-top: 1px solid @border-color;
}

.right-bordered {
  border-right: 1px solid @border-color;

  @media screen and (max-width: 991px) {
    & {
      border-right: none;
    }
  }
}

.padding-lr-8 {
  padding-right: 8px;
  padding-left: 8px;
}

.margin-b-10 {
  margin-bottom: 10px;
}

.ant-pagination {
  margin-bottom: 20px;
}

@media screen and (min-width: 992px) {
  .padding-r-column-8 {
    padding-right: 8px;
  }

  .padding-l-column-8 {
    padding-left: 8px;
  }
}

.ant-row > div[class*='ant-col'].subtitle-section {
  padding-left: 20px;
  padding-right: 20px;

  div {
    padding-top: 10px;
    padding-bottom: 20px;
    font-weight: 700;
  }
}

.disciplinary-profile {
  padding-top: 16px;
  font-size: 12px;

  a i {
    font-size: 18px;
  }
}

.historical-assignment {
  padding-top: 16px;
}

div.field-profile.ant-row {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px;

  & > div[class*='ant-col'] {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
  }

  & > div {
    padding-left: 0;
    padding-right: 0;
    padding-top: 10px;
  }
}

.text-align-center {
  text-align: center;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.bold-label {
  font-weight: bold;
  color: #000;
  font-size: 14px;
  line-height: 17px;
}

.start-number-field {
  display: flex;
  justify-content: center;

  & div label span::after {
    content: ':';
    margin: 0;
  }

  & div:first-child {
    text-align: right;
    width: auto;
  }

  & div:last-child {
    width: auto;
    text-align: left;
    font-weight: 700;
    line-height: 2.1em;
    color: rgb(0, 0, 0);
  }
}

.changelog-table-wrapper {
  padding-left: 16px;
  margin: 20px 0px 50px 0px;
}

.ant-menu-submenu-popup,
.ant-menu-submenu > .ant-menu {
  background: #041528;
}

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: rgb(0, 0, 0);
}
